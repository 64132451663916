import { createWebHistory, createRouter } from "vue-router";
import {
    CHANGE_PASSWORD_ROUTE,
    CONTACT_ROUTE,
    DETAILS_ROUTE,
    FAQ_ROUTE,
    FAVORITE_ROUTE,
    GENERAL_CONDITION_ROUTE,
    HOME_ROUTE,
    LEGAL_ROUTE,
    LOGIN_ROUTE,
    REGISTER_ROUTE,
    RESULT_ROUTE,
    SAVED_SEARCH_ROUTE,
    SAVE_SEARCH_ROUTE,
    SEARCH_ROUTE, FORGOT_PASSWORD_ROUTE,
    RESET_PASSWORD_ROUTE
} from "./routes";

const routes = [
    {
        path: LOGIN_ROUTE,
        name: "login",
        component: () => import('../components/auth/LoginView.vue'),
    },
    {
        path: REGISTER_ROUTE,
        component: () => import('../components/register/RegisterView.vue'),
    },
    {
        path: SAVED_SEARCH_ROUTE,
        name: "searchSaveList",
        component: () => import('../components/search/saved/SearchListView.vue'),
        props: true
    },
    {
        path: SAVE_SEARCH_ROUTE,
        name: "searchSave",
        component: () => import('../components/search/saved/SearchSaveView.vue'),
        props: true
    },
    {
        path: SEARCH_ROUTE,
        name: 'search',
        component: () => import('../components/search/SearchView.vue'),
    },
    {
        path: RESULT_ROUTE,
        name: "result",
        component: () => import('../components/search/ResultView.vue'),
    },
    {
        path: DETAILS_ROUTE,
        name: "details",
        component: () => import('../components/avis/detailsView.vue'),
        props: true
    },
    {
        path: FAVORITE_ROUTE,
        name: "favorites",
        component: () => import('../components/favorites/FavoriteListView.vue'),
        props: true
    },
    {
        path: FORGOT_PASSWORD_ROUTE,
        name: "forgotPassword",
        component: () => import('../components/password/ForgotPasswordView.vue'),
        props: true
    },
    {
        path: CHANGE_PASSWORD_ROUTE,
        name: "changePassword",
        component: () => import('../components/password/ChangePasswordView.vue'),
        props: true
    },
    {
        path: RESET_PASSWORD_ROUTE,
        name: "resetPassword",
        component: () => import('../components/password/ResetPasswordView.vue'),
        props: true
    },
    {
        path: LEGAL_ROUTE,
        name: "legal",
        component: () => import('../components/staticPages/LegalView.vue'),
    },
    {
        path: FAQ_ROUTE,
        name: "faq",
        component: () => import('../components/staticPages/FaqView.vue'),
    },
    {
        path: CONTACT_ROUTE,
        name: "contact",
        component: () => import('../components/staticPages/ContactView.vue'),
    },
    {
        path: GENERAL_CONDITION_ROUTE,
        name: "generalConition",
        component: () => import('../components/staticPages/GeneralConditionView.vue'),
    },
    {
        path: HOME_ROUTE,
        component: () => import('../components/main/MainView.vue'),
    },

]

const index = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
    linkActiveClass: 'active',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
})

index.beforeEach((to, from, next) => {
    if (to.path !== LOGIN_ROUTE && to.path !== CHANGE_PASSWORD_ROUTE && to.path !== RESET_PASSWORD_ROUTE && to.path !== FORGOT_PASSWORD_ROUTE) {
        localStorage.setItem('lastRoute', to.fullPath);
    }
    next();
});

export default index;