import axios from "axios";

let url = process.env.VUE_APP_API;

class PasswordService {


    reset(user) {
        return axios.post(url + '/api/account/resetpassword', {
            email: user.email,
            token: user.token,
            newPassword: user.newPassword,
            confirmNewPassword: user.confirmNewPassword,
        }
        ).then(
            (response) => {
                return response.data;
            }
        )
    }
    forgotPassword(user) {
        return axios.post(url + '/api/account/forgotpassword', {
                email: user.email
            }
        ).then(
            (response) => {
                return response.data;
            }
        )
    }
    change(user) {
        const accessToken = localStorage.getItem('token');
        const config = {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`
            }
        };
        return axios.post(url + '/api/account/changepassword', {
            currentPassword: user.currentPassword,
            newPassword: user.newPassword,
            confirmNewPassword: user.confirmNewPassword
        }, config
        ).then(
            (response) => {
                return response.data;
            }
        )
    }

}

export default new PasswordService();