<template>
  <div class="footer-container">
    <v-row class="py-5 footer-sub-container">
      <v-col>
        <v-row no-gutters>
          <div>
            <v-img :src="require('@/assets/images/copyright.png')" width="320"></v-img>
          </div>
        </v-row>
      </v-col>
      <v-col class="align-center d-flex">
        <v-row class="footer-text-size">
          <v-col class="d-flex" style="justify-content: end;">
            <div class="px-2">A propos</div>
            <div class="px-1">|</div>
            <router-link class="px-2" style="text-decoration: none;color: white" :to="LEGAL_ROUTE">Mentions légales</router-link>
            <div class="px-1">|</div>
            <router-link class="px-2" style="text-decoration: none;color: white" :to="FAQ_ROUTE">FAQ</router-link>
            <div class="px-1">|</div>
            <div class="px-2">Contact</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { FAQ_ROUTE, LEGAL_ROUTE } from '@/router/routes';


export default {
  name: 'FooterView',
  data() {
    return {
      FAQ_ROUTE,
      LEGAL_ROUTE
    }
  },
  methods: {}


}
</script>
<style>
.footer-sub-container {
  color: #F7F9FB;
  font-family: Rubik-Regular;
  font-size: 0.52738rem;
  font-style: normal;
  font-weight: 300;
  line-height: 0.64456rem;
  padding-left: 14.71rem !important;
  padding-right: 14.71rem !important;
  /* 122.222% */
}

.footer-container {
  background-color: #192937!important;
  width: 100%;
}

.footer-text-size {
  color: #FFF;
  font-family: Rubik-Regular;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.41019rem;
  /* 116.667% */
  letter-spacing: 0.01756rem;
}

/*@mediaSection*/
@media (max-width: 600px) {
  .footer-sub-container {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    margin-top: 3.34rem;
  }
}
@media (orientation: landscape) and (max-height: 500px) {
  .footer-sub-container {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-size: 0.5rem;
  }
  .footer-container {
    padding-top: 1rem;
  }
  .footer-text-size {
    font-size: 0.5rem;
    line-height: 0.5rem;
  }
}
</style>